import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from 'react-bootstrap'
import BannerUpdate from '../components/BannerUpdate'
import MailChimpForm from '../components/MailChimpForm'
import UpdateLoad from '../components/UpdateLoad'
import { BookmarkFill } from "react-bootstrap-icons";
import { Seo } from '../components/Seo'
import metaImageOg from '../assets/images/updates-og.png';
import { useLocation } from "@reach/router"

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulUpdatesFeaturedArticle:{
        nodes:ub
    }} = data;

    
    const pathLocation = useLocation();
    const url = pathLocation.href;

    return (
    <Layout hasData={!!ub}>
        <Seo 
            title="Updates - Sogody" 
            description="Deep dive into tech and strategy updates of the Martech industry and beyond." 
            image={'https://sogody.com'+metaImageOg}
            url={url}
        />
        <div className="up-final"> 
        <BannerUpdate/>
        <Container className="up-container">
            {/* <UpdateBox/> */}
            {ub.map((ub)=>{ 
                return <a href={`/updates/${ub.update.upSlug}/`}> <div className="up-banner-article" style={{backgroundImage: `url(${ub.image.fluid.src})`}}>
                    <div className="article-bn">
                        <p className="title"> <BookmarkFill/>{ub.title}</p>
                        <p className="subtitle">  {ub.subtitle}</p>
                        <p className="date">{ub.date}</p>
                    </div>
            </div> </a>
            })}
            <UpdateLoad/>   
            <MailChimpForm/>
        </Container>
        </div>
    </Layout>
    )
}

export const query = graphql`
{
    allContentfulUpdatesFeaturedArticle(limit: 1, filter: {node_locale: {eq: "en-US"}}, sort: {fields: id}) {
        nodes {
        subtitle
        title
        date(formatString: "DD MMMM YYYY")
        update {
            upSlug
        }
        image {
            fluid(quality: 100) {
            src
            }
        }
        }
    }
}
`
